import React from "react";

const Project = ({item}) => {
    return (
        <div key={item.id} className="flex flex-col items-center text-center mb-4" >
            <h3 className="text-2xl font-semibold capitalize mb-6 ">{item.name}</h3>
        <div className="mb-8 ">
            <img className="scale-100 rounded-lg  transition-all duration-300 hover:scale-95" src={item.image} alt="projectos" />
        </div>
        {/* <p className="capitalize text-accent text-sm mb-3 ">{item.category}</p> */}
        
        <div className='flex space-x-5 items-center justify-center'>
        <a href={item.code} target="_blank" rel="noopener noreferrer" className="btn btn-sm bg-accent hover:bg-accent-hover" > Ver Codigo </a>
        <a href={item.vivo} target="_blank" rel="noopener noreferrer" className="btn btn-sm bg-accent hover:bg-accent-hover">Ver en vivo</a>
        </div>
        </div>
    )
}

export default Project;