import React, { useState, useEffect } from "react";
import Logo from '../assets/img/LOGO.svg';
import Nav from '../components/Nav';
import NavMobile from '../components/NavMobile';
import Socials from '../components/Socials';

const Header = () => {
    const [bg, setBg] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setBg(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);

        // Limpiar el event listener cuando el componente se desmonte
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Dependencia vacía para que se añada solo una vez al montar el componente

    return (
        <header className={`${bg ? 'bg-tertiary h-20' : 'h-24'} flex items-center fixed top-0 w-full text-white z-10 transition-all duration-300`}>
            <div className='container mx-auto h-full flex items-center justify-between'>
                <a href="/">
                    <img className="size-48" src={Logo} alt="logo" />
                </a>
                <div className='hidden lg:block'>
                    <Nav />
                </div>
                <div className='hidden lg:block'>
                    <Socials />
                </div>
                <div className='lg:hidden'>
                    <NavMobile />
                </div>
            </div>
        </header>
    );
};

export default Header;
