import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Skills from './components/Skills';
import SkillsI from './components/SkillsI';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import BackTopBtn from './components/BackTopBtn';
// import components

const App = () => {
  return <div>
    <Header />
    <Hero />
    <Skills />
    <About />
    <SkillsI />
    <Portfolio />
    <Contact />
    <Footer />
    <BackTopBtn />
    </div>;
};

export default App;
