import React from "react";
import men3 from '../assets/img/header.png';


const Hero = () => {
    return (<section
        id="home" className=' lg:h-[85vh] flex items-center bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden' >
        <div className='container mx-auto h-full'>
            <div className="flex items-center h-full pt-[35px]">
                <div className="flex-1 flex flex-col items-center lg:items-start">
                    <p className="text-lg md:text-[20px] text-accent mb-[2px]">¡Hola!, Soy Hernán M. 👋🏼</p>
                    <h1 className="text-4xl leading-[44px] md:text-5xl md:leading-tight lg:text-[45px] 
                lg:leading-[1.2] font-bold md:tracking-[2px]">
                        Desarollador <br /> Front End. </h1>
                    <p className="text-1xl pt-1 pb-8 md:pt-5 md:pb-10 max-w-[480px] text-center lg:text-left">
                        Desarrollador Frontend comprometido con la excelencia técnica
                        y la experiencia del usuario.
                        Licenciado en Ciencias Sociales, fotografo, perspicaz
                        Busco desafíos que impulsen mi crecimiento profesional.</p>
                    <button
                        className="btn btn-md bg-accent hover:bg-accent-hover md:btn-lg transition-all"
                        onClick={() => window.open("https://i.postimg.cc/FzRc95x7/CV-Hern-n-Gustavo-Morales-Barreto.png",
                        "_blank")}>¡Descarga mi CV!</button>

                </div>
                <div className="hidden lg:flex flex-1 justify-center items-center h-full">
                    <img className="size-[450px]  " src={men3} alt="banner" />

                </div>
            </div>
        </div>

    </section>)
};

export default Hero;