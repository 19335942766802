import React from "react";
import Image from '../assets/img/about.png'


const About = () => {
    return (
        <section className="section bg-secondary " id="about"> 
        <div className="container mx-auto">
            <div className="flex flex-col xl:flex-row gap-24">
                <img src={Image} alt="about" className="object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded-2xl" />
                <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
                    <div className="flex flex-col">
                    <h2 className="text-3xl lg:text-4xl font-medium lg:font-extrabold before:content-about relative 
                    before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
                        Hernán Morales
                    </h2>
                    <p className="mb-8 text-accent">
                    Desarrollador front-end</p>
                    <hr className="mb-8 opacity-5" />
                    <p className="mb-8">
                    Como desarrollador front-end, tengo una sólida formación en herramientas de creacion visual, junto con experiencia en Photoshop y Lightroom para optimizar la experiencia visual de los proyectos web.
                    <br /> <br />Mi enfoque se centra en crear interfaces intuitivas y atractivas que generen un impacto positivo en los usuarios.
                    <br /> <br />Mi formación en ciencias sociales me ha proporcionado una comprensión profunda de las interacciones humanas y los contextos culturales, lo que me permite diseñar experiencias web que resuenen con una amplia gama de audiencias. </p>
                    </div>
                    <button className="btn btn-md bg-accent hover:bg-accent-hover transition-all"
                    onClick={() => window.open("https://www.linkedin.com/in/hernán-g-morales/",
                        "_blank")}>Contactame
                    </button>
                </div>
            </div>
        </div>
        </section>
    )
}

export default About;