import React from "react";
import { contact } from '../data';
import Swal from 'sweetalert2';

const Contact = () =>{

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "78238619-511b-490c-b55e-fdc1030e6de6");
    
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
    
        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());
    
        if (res.success) {
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Tu mensaje ha sido enviado",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
    }
            });
        }
    };

    return (
        <section className="section bg-tertiary" id="contact">
            <div className="container mx-auto">
                <div className="flex flex-col items-center text-center">
                    <h2 className="section-title before:content-contact relative before:absolute before:opacity-40 
                    before:-top-7 before:-left-40 before:hidden before:lg:block">
                    Contactame
                    </h2>
                    <p className="subtitle">
                    Construyamos juntos experiencias web extraordinarias.
                    </p>
                </div>
                <div className="flex flex-col lg:gap-x-8 lg:flex-row">
                    <div className="flex flex-1 flex-col items-start space-y-8 mb-12 lg:mb-0 lg:pt-2">
                        {contact.map((item, index) =>{
                            const {icon, title, subtitle, description } = item;
                            return (<div className="flex flex-col lg:flex-row gap-x-4" key={index}> 
                            <div className="text-accent rounded-sm w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 
                            text-2xl">{icon}</div>
                            <div>
                            <h4 className="font-body text-xl mb-1">{title}</h4>    
                            <p className="mb-1">{subtitle}</p>
                            <p className="text-accent font-normal">{description}</p>
                            </div>
                            </div>)
                            ;
                        })}
                    </div>
                    <form onSubmit={onSubmit} className="space-y-8 w-full max-w-[780px]">
                        <div className="flex gap-8">
                            <input type="text" className="input" placeholder="Tu Nombre" name="name" autocomplete="off" required />
                            <input type="email" className="input" placeholder="Tu Email" name="email" autocomplete="off" required/>
                        </div>
                        <input type="text" className="input" placeholder="Asunto" autocomplete="off" />
                        <textarea className="textarea" placeholder="Tu Mensaje" name="message" autocomplete="off" ></textarea>
                        <button className="btn btn-lg bg-accent hover:bg-accent-hover">
                            Envia tu mensaje
                        </button>
                    </form>
                </div>
            </div>

        </section>
    )
};

export default Contact;