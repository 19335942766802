//  icons
import {
  FiLinkedin,
  FiInstagram,
  FiGithub,
  FiMail,
  FiMapPin,
} from 'react-icons/fi';

// companies icons
import TopSkillImg1 from './assets/img/brands/html5.png';
import TopSkillImg2 from './assets/img/brands/css3.png';
import TopSkillImg3 from './assets/img/brands/js.png';
import TopSkillImg4 from './assets/img/brands/reactjs.png';
import TopSkillImg5 from './assets/img/brands/git.png';
import TopSkillImg6 from './assets/img/brands/figma.png';
import TopSkillImg7 from './assets/img/brands/ps.png';
import TopSkillImg8 from './assets/img/brands/sass.png';

// projects images
// import Project1 from './assets/img/projects/p1.png';
// import Project2 from './assets/img/projects/p2.png';
// import Project3 from './assets/img/projects/p3.png';
// import Project4 from './assets/img/projects/p4.png';
// import Project5 from './assets/img/projects/p5.png';
// import Project6 from './assets/img/projects/p6.png';
// import Project7 from './assets/img/projects/p7.png';
// import Project8 from './assets/img/projects/p8.png';
// import Project9 from './assets/img/projects/p9.png'
// import Project10 from './assets/img/projects/p10.png'
// skills images
import SkillImg1 from './assets/img/skills/adobeLightroom.png';
import SkillImg2 from './assets/img/skills/boots.png';
import SkillImg3 from './assets/img/skills/jquery.png';
import SkillImg4 from './assets/img/skills/nextjs.png';
import SkillImg5 from './assets/img/skills/nodejs.png';
import SkillImg6 from './assets/img/skills/pr.png';
import SkillImg7 from './assets/img/skills/tailwind.png';
import SkillImg8 from './assets/img/skills/visual.png';

// navigation
export const navigation = [
  {
    name: 'Inicio',
    href: 'home',
  },
  {
    name: 'Acerca de mi',
    href: 'about',
  },
  {
    name: 'Portfolio',
    href: 'portfolio',
  },
  {
    name: 'Contacto',
    href: 'contact',
  },
];

// social
export const social = [
  {
    icon: <FiLinkedin />,
    href: 'https://www.linkedin.com/in/hernán-g-morales/',
  },
  {
    icon: <FiInstagram />,
    href: 'https://www.instagram.com/gustav_morales/',
  },
  {
    icon: <FiInstagram />,
    href: 'https://www.instagram.com/tommark.visuals/',
  },
  {
    icon: <FiGithub />,
    href: 'https://github.com/HernanGmorales',
  },
];

// companies
export const TopSkills = [
  {
    img: TopSkillImg1,
    href: '',
  },
  {
    img: TopSkillImg2,
    href: '',
  },
  {
    img: TopSkillImg3,
    href: '',
  },
  {
    img: TopSkillImg4,
    href: '',
  },
  {
    img: TopSkillImg5,
    href: '',
  },
  {
    img: TopSkillImg6,
    href: '',
  },
  {
    img: TopSkillImg7,
    href: '',
  },
  {
    img: TopSkillImg8,
    href: '',
  },
];

// projects
export const projectsData = [
  {
    id: '1',
    image: 'https://i.postimg.cc/xjNmMFzm/p1.png',
    name: 'Ecommerce Society',
    category: 'Ecommerce',
    code: "https://github.com/HernanGmorales/TiendaSociety.git",
    vivo: "https://hernangmorales.github.io/TiendaSociety/",
  },
  {
    id: '2',
    image: 'https://i.postimg.cc/m2163cxX/p2.png',
    name: 'Ecommerce TuMoto',
    category: 'Ecommerce',
    code: "https://github.com/HernanGmorales/TuMotoTienda.git",
    vivo: "https://hernangmorales.github.io/TuMotoTienda/",
  },
  {
    id: '3',
    image: 'https://i.postimg.cc/jSpGGHTJ/p6.png',
    name: 'Portfolio Web',
    category: 'Web development',
    code: "https://github.com/HernanGmorales/Proyecto-PorfolioWeb.git",
    vivo: "https://hernangmorales.github.io/Proyecto-PorfolioWeb/build/",
  },
  {
    id: '4',
    image: 'https://i.postimg.cc/d3GFx5k3/p4.png',
    name: 'Kairos360',
    category: 'Consumo de API',
    code: "https://github.com/HernanGmorales/Kairos360",
    vivo: "https://hernangmorales.github.io/Kairos360/",
  },
  {
    id: '5',
    image:  'https://i.postimg.cc/dtzdd3v9/p5.png',
    name: 'Portfolio Fotografico',
    category: 'Web development',
    code: "https://github.com/HernanGmorales/GaleriaResponsive",
    vivo: "https://hernangmorales.github.io/GaleriaResponsive/",
  },
  {
    id: '6',
    image: 'https://i.postimg.cc/cJpZsqNT/p8.png',
    name: 'Coffe GO!',
    category: 'Ecommerce',
    code: "https://github.com/HernanGmorales/Coffe-GO-",
    vivo: "https://hernangmorales.github.io/Coffe-GO-/",
  },
  {
    id: '7',
    image: 'https://i.postimg.cc/dVpC21YB/p3.png',
    name: 'Catalogo de peliculas',
    category: 'Consumo de API',
    code: "https://github.com/HernanGmorales/PopCinema",
    vivo: "https://hernangmorales.github.io/PopCinema/",
  },

  
  {
    id: '8',
    image: 'https://i.postimg.cc/jqBmW6W3/p7.png',
    name: 'WoW Comic',
    category: 'Ecommerce',
    code: "https://github.com/HernanGmorales/WowComic.git",
    vivo: "https://drive.google.com/file/d/1f7TCqHwGkA7o3Cfw4m8UY9-px6QeHEA8/view?usp=sharing",
  },
  {
    id: '9',
    image: 'https://i.postimg.cc/J0sffPsZ/p9.png',
    name: 'Gestor de Gastos',
    category: 'Web development',
    code: "https://github.com/HernanGmorales",
    vivo: "hernangmorales.github.io/AppDeGastos/",
  },
  {
    id: '10',
    image: 'https://i.postimg.cc/Pq4zHHZn/p10.png',
    name: 'Porfolio Dev',
    category: 'Web development',
    code: "",
    vivo: "https://hernanmoralesdev.com",
  },

];

// projects
export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'Ecommerce',
  },
  {
    name: 'Web development',
  },
  {
    name: 'Consumo de API',
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
  {
    image: SkillImg8,
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: '¿Quieres más información?',
    subtitle: 'Estoy aqui para ayudarte',
    description: 'Envíeme un correo electrónico a hernangmoralesb@gmail.com',
  },
  {
    icon: <FiMapPin />,
    title: 'Ubicación',
    subtitle: 'Colombia',
    description: 'Sirviendo a clientes en todo el mundo',
  },
];
