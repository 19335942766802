import React from "react";
import {TopSkills} from '../data';


const SkillsI = () => {
    return (
        <section className="min-h-[146px] bg-tertiary flex items-center">
            <div className="container mx-auto flex md:justify-between items-center flex-wrap justify-evenly">
                {
                    TopSkills.map((brand, index)=>{
                        return <div key={index}>
                            <img className="rounded-lg  transition-all duration-300 hover:scale-110"  src={brand.img} alt="img" />
                            </div>
                    })
                }
            </div>
        </section>
    )
}

export default SkillsI;